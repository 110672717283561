import React from 'react'

class Resume extends React.Component {
  render() {
    return (
      <div>
        <h2>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1VOI8S6dGkntHZAsRbwwynlhn_B477ICw/view?usp=sharing"
          >
            Resume <i className="icon fa-link"></i>
          </a>
        </h2>
        {/* <Document file="Resume2019.pdf" /> */}

        {/* <div className="body">
          <div className="skills">
            <h4>Skills</h4>
            <table>
              <tr>
                <td>
                  <ul className="fa-ul">
                    <li>Python</li>
                    <li>Javascript</li>
                    <li>Typescript</li>
                    <li>C</li>
                    <li>HTML</li>
                    <li>CSS</li>
                  </ul>
                </td>
                <td>
                  <ul className="fa-ul">
                    <li>Angular</li>
                    <li>Django</li>
                    <li>Node.js</li>
                    <li>Git</li>
                    <li>Linux</li>
                    <li>JQuery</li>
                    <li>OOP</li>
                  </ul>
                </td>
              </tr>
            </table>
          </div>
          <div className="experience">
            <h4>Experience</h4>
          </div>
          <div className="projects">
            <h4>Projects</h4>
          </div>
          <div className="education">
            <h4>Education</h4>
          </div>
        </div> */}
      </div>
    )
  }
}

export default Resume
