import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'
import Resume from '../components/Resume'

import thumb01 from '../assets/images/fulls/01.jpg'
import thumb02 from '../assets/images/fulls/02.jpg'
import thumb03 from '../assets/images/fulls/03.jpg'

import full01 from '../assets/images/fulls/01.jpg'
import full02 from '../assets/images/fulls/02.jpg'
import full03 from '../assets/images/fulls/03.jpg'

const DEFAULT_IMAGES = [
  {
    id: '1',
    source: full01,
    thumbnail: thumb01,
    caption: `Recipe Book Application (WIP)`,
    github: 'https://github.com/dangjoeltang/AngularGitRecipes',
    url: 'http://gitrecipes.netlify.com',
    description:
      'Check the github page for guest login credentials. Online social recipe application. User JWT authentication, CDN media storage using Amazon S3 and Cloudfront. Image upload via presigned S3 URLs.',
  },
  {
    id: '2',
    source: full02,
    thumbnail: thumb02,
    caption: 'Ebay to Fedex Shipping Chrome Extension',
    github: 'https://github.com/dangjoeltang/ebay-Auto-Fedex',
    url:
      'https://chrome.google.com/webstore/detail/fedex-quickship/dfmbabhglgmlobabfaggddfgnpinnfek?hl=en',
    description:
      'Simple extension to automatically copy the shippiing details from the ebay shipment page to Fedex shipment manager.',
  },
  {
    id: '3',
    source: full03,
    thumbnail: thumb03,
    caption: 'Recipe Book Application Django Rest Framework',
    github: 'https://github.com/dangjoeltang/GitRecipes',
    url: 'http://gitrecipes.herokuapp.com/api',
    description:
      'Django REST API backend hosted on Heroku. Authentication is handled using JSON Web Tokens, and data is stored on a PostgreSQL database.',
  },
]

class HomeIndex extends React.Component {
  render() {
    const siteTitle = 'Gatsby Starter - Strata'
    const siteDescription = 'Site description'

    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>

        <div id="main">
          <section id="one">
            <header className="major">
              <h2>
                <br />
                9/9 - In process of migrating to GatsbyJS and React. Old page
                can be found <a href="https://dangjoeltang.github.io">here.</a>
              </h2>
            </header>
            <p>
              I'm not one for deep quotes or trying to appear more creative and
              philosophical than I am. This is my portfolio site, where I will
              keep a catalog of my more involved personal projects.
            </p>
            <p>
              I hated keeping a journal as a kid, but now I feel like it is more
              important that ever to document aspects of my life. Priorities and
              mindsets change over time, and what is important to me today may
              seem frivolous in ten years. In the future I want to be able to
              look back and remember the things that I was passionate about.
            </p>
            {/* <ul className="actions">
              <li>
                <a href="#" className="button">
                  Learn More
                </a>
              </li>
            </ul> */}
          </section>

          <section id="two">
            <h2>Recent Work</h2>

            <Gallery
              images={DEFAULT_IMAGES.map(
                ({
                  id,
                  source,
                  thumbnail,
                  caption,
                  github,
                  url,
                  description,
                }) => ({
                  source,
                  thumbnail,
                  caption,
                  github,
                  url,
                  description,
                })
              )}
            />

            {/* <ul className="actions">
              <li>
                <a href="#" className="button">
                  Full Portfolio
                </a>
              </li>
            </ul> */}
          </section>

          {/* <section id="three">
            <Resume />
          </section> */}

          <section id="four">
            <h2>Get In Touch</h2>
            <p>
              It's usual easiest to just send me an email or message me on
              LinkedIn directly. My contact information is available below.
            </p>
            <div className="row">
              <div className="4u 12u$(small)">
                <ul className="labeled-icons">
                  <li>
                    <h3 className="icon fa-envelope-o">
                      <span className="label">Email</span>
                    </h3>
                    <a href="mailto:qiaoweitang@protonmail.com">
                      qiaoweitang@protonmail.com
                    </a>
                  </li>
                  <li>
                    <h3 className="icon fa-linkedin">
                      <span className="label">LinkedIn</span>
                    </h3>
                    <a href="https://www.linkedin.com/in/qiaoweitang/">
                      <span className="label">LinkedIn</span>
                    </a>
                  </li>
                  <li>
                    <h3 className="icon fa-github">
                      <span className="label">Github</span>
                    </h3>
                    <a href="https://github.com/dangjoeltang">
                      <span className="label">Github</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
